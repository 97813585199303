<!-- ai解析主观题 -->
<template>
  <div class="ai_mian_box">
    <van-field v-model="input_text" rows="5" :disabled="disabled" type="textarea" class="my_input" placeholder="请输入" @input="changedInputValue" />
    <!-- 判断是否展示 -->
    <div v-if="canShowPhotoMenu() || canShowMicrophoneMenu()" class="menu_box">
      <div v-if="canShowPhotoMenu()" class="use_camera_box">
        <span class="capture_btn fileinput-button">
          <span>拍照</span>
          <input ref="fileInput" :disabled="isloadingImg || disabled" type="file" accept="image/*" @change="onFileChange">
        </span>
      </div>
      <div v-if="canShowMicrophoneMenu()" class="use_microphone_box">
        <button v-if="!isRecording" :disabled="disabled" class="capture_btn" @click="startRecordBtn">发语音</button>
        <button v-else class="capture_btn" @click="stopRecording">停止录音</button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import Recorder from './recorder'
import { getAiResultByPhoto } from '@/api/chatGPT.js'

export default {
  props: {
    // 配置key和token，不在当前页面请求，因为在试卷中，主观题多的话会请求多次接口
    config: {
      type: Object,
      require: true,
      default: () => {
        return {
          appkey: '',
          token: ''
        }
      }
    },
    // 1 文字录入 2 图片上传 3 语音录入：暂时文字输入框必有判断 2 和 3 即可
    answerType: {
      type: String,
      default: '1'
    },
    // 解析禁用
    disabled: {
      type: Boolean,
      default: false
    },
    // 继续答题，用户答案回显
    oldAnswer: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      // 输入框绑定值
      input_text: '',
      // 拍照
      // 是否可用摄像头
      canUseCamera: false,
      // 图片上传解析中
      isloadingImg: false,
      // 上传地址
      uploadImgUrl: 'https://f.zhulong.com/uploadZhulongImgForEdit.php',
      // 选择的图片file
      selectedImgFile: '',
      // 图片URL
      imageUrl: '',
      // 语音
      // 是否可用麦克风
      canUseMicrophone: false,
      // 录音对象
      mediaRecorder: null,
      // 持续录音的音频数据流
      audioChunks: [],
      // 录音文件
      audioBlob: null,
      // 是否在录音
      isRecording: false,
      // 上传录音获取的URL
      audioUrl: '',
      // 采用ai英语的录音模式
      recorder: new Recorder({
        sampleBits: 16, // 采样位数，支持 8 或 16，默认是16
        sampleRate: 16000, // 采样率，支持 11025、16000、22050、24000、44100、48000，根据浏览器默认值，我的chrome是48000
        numChannels: 1, // 声道，支持 1 或 2， 默认是1
        compiling: true // (0.x版本中生效,1.x增加中)  // 是否边录边转换，默认是false
      }),
      // 录音转的文本
      speechToText: '',
      // socket
      interval: null,
      websocket: null,
      websocket_task_id: ''

    }
  },
  watch: {
    oldAnswer: {
      handler(newValue, oldValue) {
        console.log('Prop message changed from', oldValue, 'to', newValue)
        if (oldValue === undefined) {
          // 在这里执行你需要的操作
          if (this.input_text !== newValue) {
            this.input_text = newValue
          }
        }
      },
      deep: true,
      immediate: true
    }
  },
  created() {
    // this.checkMicrophoneAccess()
    // this.checkCameraAccess()
  },
  methods: {
    // 检测是否展示语音按钮，外部传入answerType检测
    canShowMicrophoneMenu() {
      if (this.answerType.includes('3')) {
        return true
      }
      return false
    },
    // 检测是否展示拍照按钮，外部传入answerType检测
    canShowPhotoMenu() {
      if (this.answerType.includes('2')) {
        return true
      }
      return false
    },
    // 输入文本发生变化
    changedInputValue(val) {
      // 值的变化按成功回调算
      this.successCallBack(val)
    },
    // input 选择了图片
    onFileChange(event) {
      const file = event.target.files[0]
      if (file) {
        this.selectedImgFile = file
        this.imageUrl = URL.createObjectURL(file)
        // 上传图片
        this.uploadImg()
      } else {
        this.errorCallBack(1, '图片选择失败')
      }
      // 重置 input 以允许再次触发
      this.$refs.fileInput.value = null
    },
    // 上传图片
    uploadImg() {
      this.isloadingImg = true
      const formData = new FormData()
      formData.append('files', this.selectedImgFile)
      axios.post(this.uploadImgUrl, formData).then((res) => {
        const data = res.data || {}
        const img_url = data.url || ''
        if (data.state === 'SUCCESS' && (img_url && img_url.length > 0)) {
          this.imageUrl = img_url
          this.uploadImgForAnalysis()
        } else {
          this.isloadingImg = false
          this.errorCallBack(1, data.state || '图片上传失败')
        }
      }).catch(error => {
        console.error('Error:', error)
        this.isloadingImg = false
        this.errorCallBack(1, '图片上传失败')
      })
    },
    // 上传解析图片
    uploadImgForAnalysis() {
      getAiResultByPhoto({ image: this.imageUrl }).then(res => {
        const json = JSON.parse(res)
        const text = json.text || ''
        this.input_text += text
        this.successCallBack(this.input_text)
        this.isloadingImg = false
      }).catch((e) => {
        console.log('图片解析出错', e)
        // ai识别失败
        this.isloadingImg = false
        this.errorCallBack(1, '图片解析出错')
      })
    },
    // 按钮开始录音按钮
    beforeStartReord() {

    },
    // 开始录音
    startRecordBtn() {
      if (this.isRecording) {
        // 停止录音
        this.stopRecording()
      } else {
        if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
          navigator.mediaDevices.getUserMedia({ audio: true }).then(stream => {
            // 获取麦克风成功
            this.startRecording()
          }).catch(() => {
            this.errorCallBack(2, '麦克风访问失败')
          })
        } else {
          this.errorCallBack(2, '暂不支持获取麦克风')
        }
      }
    },
    // 开始录音
    startRecording() {
      this.isRecording = true
      this.speechToText = ''
      this.initWebSocket()
    },
    // 结束录音
    stopRecording() {
      console.log('结束录音', this.speechToText)
      // 先复制
      this.input_text += this.speechToText
      this.successCallBack(this.input_text)
      // 停止录音
      clearTimeout(this.interval)
      // 停止websocket
      this.websocket.close()
      this.websocket = null
      this.isRecording = false
      this.speechToText = ''
      this.recorder.stop() // 停止录音
    },
    // 开始录音
    startRecordAudio() {
      var that = this
      Recorder.getPermission().then(() => {
        that.recorder.start().then(() => {
          console.log('开始录音')
          that.isRecording = true
          that.recorder.start()
          // .then(() => {
          //   // 开始录音
          //   that.isRecording = true
          // }, (error) => {
          //   console.log(error, `出错了`)
          //   that.recordingError('录音出错')
          // }).catch(e => {
          //   console.log(e, `出错了ee`)
          // })
          that.recorder.onprogress = function(params) {
            console.log('录音时长(秒)', params.duration)
          }
        }, (error) => {
          console.log(`${error.name} : ${error.message}`)
          this.recordingError('当前无麦克风权限')
        })
      })
    },

    // 录音出错，处理恢复原值
    recordingError(tips) {
      this.isRecording = false
      this.speechToText = ''
      this.errorCallBack(2, tips)
    },
    /** webSocket 相关 **/
    initWebSocket() {
      console.log('初始化weosocket')
      // 检测如果未关闭、则先关闭在重连
      if (this.websocket !== null) {
        this.websocket.close()
        this.websocket = null
      }
      // ali的websocket地址
      const wsuri = `wss://nls-gateway.aliyuncs.com/ws/v1?token=${this.config.token}`
      // 连接wss服务端
      this.websocket = new WebSocket(wsuri)
      // 指定回调函数
      this.websocket.onopen = this.websocketOnOpen
      this.websocket.onmessage = this.websocketOnMessage
      this.websocket.onerror = this.websocketOnError
      this.websocket.onclose = this.websocketClose
    },
    websocketOnOpen() {
      console.log('向 websocket 发送 链接请求')
      // 生成新的任务id
      this.websocket_task_id = this.getRandomStrNum()
      // 生成ali的请求参数message_id
      const message_id = this.getRandomStrNum()
      const actions = {
        'header': {
          'namespace': 'SpeechTranscriber', // 固定值
          'name': 'StartTranscription', // 发送请求的名称，固定值
          'appkey': this.config.appkey, // appkey
          'message_id': message_id, // 消息id
          'task_id': this.websocket_task_id // 任务id
        },
        'payload': {
          'format': 'PCM', // 音频编码格式，默认是PCM（无压缩的PCM文件或WAV文件），16bit采样位数的单声道。
          'sample_rate': 16000, // 需要与录音采样率一致、默认是16000，单位是Hz。
          'enable_intermediate_result': true, // 是否返回中间识别结果，默认是false。
          'enable_punctuation_prediction': true, // 是否在后处理中添加标点，默认是false。
          'enable_inverse_text_normalization': true, // 是否在后处理中执行数字转写，默认是false。
          'max_sentence_silence': 500//	语音断句检测阈值，静音时长超过该阈值会被认为断句，参数范围200ms～2000ms，默认值800ms。
        }
      }
      // 发送请求
      this.websocketSend(JSON.stringify(actions))
    },

    // 向websocket发消息
    websocketSend(data) {
      // console.log(this.websocket.readyState, 'websocket 数据发送', data)
      // 判断是否连接成功,连接成功再发送数据过去
      if (this.websocket.readyState === 1) {
        this.websocket.send(data)
      } else {
        console.log('websock未连接-------------------')
      }
    },
    websocketOnMessage(e) {
      // 接受ali 语音返回的数据
      const ret = JSON.parse(e.data)
      // 判断返回的数据类型
      if (ret.header.name === 'TranscriptionResultChanged') {
        // 数据在收集中 一句话的中间结果
        console.log('数据在收集中')
        // 实时获取语音转文本的结果
        // this.ingText(ret.payload.result)
      } else if (ret.header.name === 'SentenceBegin') {
        // 一句话开始后，就可以启动录音了
        console.log('检测到了一句话的开始')
        // 添加一个新的p标签、用于显示中间变化状态
        // var span = document.createElement('p')
        // span.innerText = ''
        // statusDiv.appendChild(span)
      } else if (ret.header.name === 'TranscriptionStarted') {
        console.log('服务端已经准备好了进行识别，客户端可以发送音频数据了')
        // 获取音频信息，定时获取并发送
        this.interval = setInterval(() => {
          this.getPCMAndSend()
        }, 100)
        setTimeout(() => {
          this.startRecordAudio()
        }, 1000)
      } else if (ret.header.name === 'SentenceEnd') {
        console.log('数据接收结束', ret)
        this.endText(ret.payload.result)
      } else if (ret.header.name === 'TranscriptionCompleted') {
        console.log('服务端已停止了语音转写', ret)
      }
    },
    websocketOnError(e) {
      console.log('连接建立失败重连')
    },
    websocketClose(e) {
      console.log('websocketClose断开连接', e)
    },
    websocketSendStop() {
      console.log('向  websocket 发送 Stop指令')
      const message_id = this.getRandomStrNum()
      // actions 是首次连接需要的参数,可自行看阿里云文档
      const actions = {
        'header': {
          'message_id': message_id,
          'task_id': this.websocket_task_id,
          'namespace': 'SpeechTranscriber',
          'name': 'StopTranscription',
          'appkey': this.config.appkey
        }
      }
      // 发送结束指令
      this.websocketSend(JSON.stringify(actions))
    },
    endText(text) {
      console.log(text)
      // 获取全文
      this.speechToText += text
    },
    // 录音传给socket
    getPCMAndSend() {
      // 获取音频信息
      const NextData = this.recorder.getNextData()
      console.log('NextData', NextData)
      if (NextData) {
        // 可以在这里处理或预览每个片段
        const blob = new Blob([NextData])
        const blob_size = blob.size
        console.log('获取音频信息，并发送,blob_size:' + blob_size, blob)
        // ali最大支持3200字节的音频
        const max_blob_size = 3200// 支持1600 或3200
        let my_num = blob_size / max_blob_size
        my_num = my_num + 1
        // 切分音频发送
        for (let i = 0; i < my_num; i++) {
          var end_index_blob = max_blob_size * (i + 1)
          // 判断结束时候的分界
          if (end_index_blob > blob_size) {
            end_index_blob = blob_size
          }
          // 切分音频
          var blob2 = blob.slice(i * max_blob_size, end_index_blob)
          // 生成新的blob
          const newbolb = new Blob([blob2], { type: 'audio/pcm' })
          // 发送
          this.websocketSend(newbolb)
        }
      }
    },
    // 获取随机id
    getRandomStrNum() {
      var s = []
      var hexDigits = '0123456789abcdef'
      for (var i = 0; i < 32; i++) {
        s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1)
      }
      s[14] = '4' // bits 12-15 of the time_hi_and_version field to 0010
      s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1) // bits 6-7 of the clock_seq_hi_and_reserved to 01
      s[8] = s[13] = s[18] = s[23]
      var uuid = s.join('')
      return uuid
    },
    // 错误回调type:1、照片  2、语音 、tips：提示语
    errorCallBack(type = 1, tips) {
      this.$emit('error', type, tips)
    },
    // 成功回调，将答案给到每道题
    successCallBack(text) {
      // 将图片或语音转换出的文本回调回去
      this.$emit('success', text)
    },
    // 权限获取
    // 检测摄像头是否可用
    async checkCameraAccess() {
      try {
        const canAccess = await this.canAccessCamera()
        if (canAccess) {
          this.canUseCamera = true
          console.log('摄像头可以正常访问')
        } else {
          this.canUseCamera = false
          console.error('无法访问摄像头')
        }
      } catch (error) {
        this.canUseCamera = false
        console.error('无法访问摄像头')
      }
    },
    // 获取摄像头权限
    async canAccessCamera() {
      try {
        // 尝试获取媒体设备的访问权限
        const stream = await navigator.mediaDevices.getUserMedia({ video: true })
        // 如果不需要使用摄像头，应该立即停止视频流以释放摄像头资源
        const tracks = stream.getTracks()
        tracks.forEach(track => track.stop())
        return true
      } catch (err) {
        // 当用户拒绝权限、设备不可用或其他错误发生时会进入这里
        // console.error('无法访问摄像头', err)
        // // 根据不同的错误类型做出不同的响应
        // if (err.name === 'NotFoundError' || err.name === 'DevicesNotFoundError') {
        //   console.error('没有找到可用的摄像头设备')
        // } else if (err.name === 'NotAllowedError' || err.name === 'PermissionDeniedError') {
        //   console.error('用户拒绝了摄像头权限')
        // } else if (err.name === 'NotReadableError' || err.name === 'TrackStartError') {
        //   console.error('摄像头当前正在被占用')
        // } else if (err.name === 'OverconstrainedError' || err.name === 'ConstraintNotSatisfiedError') {
        //   console.error('提供的参数无法满足摄像头需求')
        // } else {
        //   console.error('未知错误:', err)
        // }
        return false
      }
    },
    // 检测是麦克风可用
    async checkMicrophoneAccess() {
      try {
        const canAccess = await this.canAccessMicrophone()
        if (canAccess) {
          this.canUseMicrophone = 1
          console.log('麦克风可以正常访问')
        } else {
          this.canUseMicrophone = 2
          console.error('无法访问麦克风')
        }
      } catch (error) {
        console.error('无法访问麦克风')
        this.canUseMicrophone = 3
      }
    },
    // 检测是否麦克风可用
    async canAccessMicrophone() {
      try {
        // 尝试获取麦克风访问权限
        const stream = await navigator.mediaDevices.getUserMedia({ audio: true })
        // 如果成功获取到麦克风访问权限，应该立即停止所有轨道以释放资源
        const tracks = stream.getTracks()
        tracks.forEach(track => track.stop())
        return true
      } catch (err) {
        // 捕获并处理错误
        // if (err.name === 'NotAllowedError' || err.name === 'PermissionDeniedError') {
        //   console.error('用户拒绝了麦克风权限')
        // } else if (err.name === 'NotFoundError' || err.name === 'DevicesNotFoundError') {
        //   console.error('没有找到可用的麦克风设备')
        // } else if (err.name === 'NotReadableError' || err.name === 'TrackStartError') {
        //   console.error('麦克风当前正在被占用或无法读取')
        // } else if (err.name === 'OverconstrainedError' || err.name === 'ConstraintNotSatisfiedError') {
        //   console.error('提供的参数无法满足麦克风需求')
        // } else {
        //   console.error('未知错误:', err)
        // }
        return false
      }
    },
    // 获取麦克风权限
    getMicrophoneAccess() {
      if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
        navigator.mediaDevices.getUserMedia({ audio: true })
          .then(stream => {
            // 获取麦克风成功，可以在这里处理流，例如播放等
            console.log('麦克风访问成功')
            // // 播放音频
            // const audio = new Audio();
            // audio.srcObject = stream;
            // audio.play();
          })
          .catch(error => {
            console.error('麦克风访问失败：', error)
          })
      } else {
        alert('您的浏览器不支持获取麦克风')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.ai_mian_box {
  // width: 100%;
  padding: 30px 30px;
  font-size: 24px;
  .my_input {
    border: 1px solid #ccc;
  }
}
.menu_box {
  margin-top: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}
.use_camera_box {
  display: flex;
  align-items: center;
  .fileinput-button {
    position: relative;
    display: inline-block;
    overflow: hidden;
  }
  .fileinput-button input{
    position:absolute;
    top: 0px;
    right: 0px;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    -ms-filter: 'alpha(opacity=0)';
    font-size: 200px;
  }
}
.use_microphone_box {
  margin-left: 40px;
}
.use_camera_box, .use_microphone_box {
  .tip_p {
    margin: 30px 0 10px;
  }
  .capture_btn {
    padding: 10px 20px;
    border:  2px solid #ee2e2e;
    color: #ee2e2e;
    font-size: 26px;
    border-radius: 40px;
    line-height: 30px;
    background-color: #fff;
  }
}
</style>

