import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () =>
      import('@/views/home/index')
  },
  {
    path: '/tupianku',
    name: 'tupianku',
    component: () => import('@/views/tupianku/index')
  },
  {
    path: '/tupianku/categoryList',
    name: 'categoryList',
    component: () => import('@/views/tupianku/cateLists.vue')
  },
  {
    path: '/zhibo',
    name: 'zhibo',
    component: () => import('@/views/home/zhibo')
  },
  {
    path: '/banji',
    name: 'banji',
    component: () => import('@/views/home/banji')
  },
  {
    path: '/chengji',
    name: 'chengji',
    component: () => import('@/views/home/chengji')
  },
  {
    path: '/chakan',
    name: 'chakan',
    component: () => import('@/views/home/chakan')
  },
  {
    path: '/kebiao',
    name: 'kebiao',
    component: () => import('@/views/home/kebiao')
  },
  {
    path: '/wenda',
    name: 'wenda',
    component: () => import('@/views/home/wenda')
  },
  {
    path: '/tiwen',
    name: 'tiwen',
    component: () => import('@/views/home/tiwen')
  },
  {
    path: '/mwxLog',
    name: 'mwxLog',
    component: () => import('@/views/wechat/miniWXLogin')
  },
  {
    path: '/qrCode',
    name: 'QrCode',
    component: () => import('@/views/wechat/qrCode'),
    meta: { title: '扫码加好友' }

  },
  {
    path: '/openMp',
    name: 'OpenMp',
    component: () => import('@/views/wechat/openMP.vue'),
    meta: { title: '' }
  }
]
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (to.query.radar) {
    var h = document.createElement('script')
    h.src = 'https://zhulong-lib.oss-cn-beijing.aliyuncs.com/js/time_position_tools.js'
    var s = document.getElementsByTagName('script')[0]
    s.parentNode.insertBefore(h, s)
  }
  if (to.meta.content) {
    const head = document.getElementsByTagName('head')
    const meta = document.createElement('meta')
    document.querySelector('meta[name="keywords"]').setAttribute('content', to.meta.content.keywords)
    document.querySelector('meta[name="description"]').setAttribute('content', to.meta.content.description)
    meta.content = to.meta.content
    head[0].appendChild(meta)
  }
  /* 路由发生变化修改页面title */
  if (to.meta.title) {
    document.title = to.meta.title
  }
  /* 当进行页面跳转时定位在页面顶部*/
  // chrome
  document.body.scrollTop = 0
  // firefox
  document.documentElement.scrollTop = 0
  // safari
  window.pageYOffset = 0
  next()
})
// 解决vue中的NavigationDuplicated {_name: "NavigationDuplicated", name: "NavigationDuplicated"}
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
export default router
