import ucenter_request from '@/utils/ucenterrequest.js'

// 英语老师-获取token
export function getAiEnToken(params) {
  return ucenter_request({
    url: 'api/aiv2/getAiEnToken',
    method: 'POST',
    params: params
  })
}
