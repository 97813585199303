import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'lib-flexible'
import '@/styles/index.scss'
import Vant from 'vant'
import 'vant/lib/index.css'
import md5 from 'js-md5'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import JsBridge from './utils/JsBridge'

// 全局注册组件
import aiAnalysis from '@/components/AiAnalysis/index.vue'
Vue.component('AiAnalysis', aiAnalysis)

Vue.use(ElementUI)
Vue.prototype.$md5 = md5
Vue.use(Vant)

import wx from 'weixin-js-sdk'
Vue.prototype.$wx = wx
Vue.prototype.$jsbridge = JsBridge

// Vue.config.productionTip = false
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
