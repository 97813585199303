<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
import WechatState from '@/utils/isWechatState.js'
import Cookie from 'js-cookie'

// Android 调用 js初始化
function connectWebViewJavascriptBridge(callback) {
  if (window.WebViewJavascriptBridge) {
    // eslint-disable-next-line no-undef
    callback(WebViewJavascriptBridge)
  } else {
    document.addEventListener(
      'WebViewJavascriptBridgeReady'
      , function() {
        // eslint-disable-next-line no-undef
        callback(WebViewJavascriptBridge)
      },
      false
    )
  }
}
// 注册回调函数，第一次连接时调用 初始化函数
connectWebViewJavascriptBridge(function(bridge) {
  // 初始化(只可以初始化一次)
  bridge.init(function(message, responseCallback) {
    const data = {
      'Javascript Responds': 'Wee!'
    }
    responseCallback(data)
  })
})
export default {
  provide() {
    return {
      reload: this.reload
    }
  },
  data() {
    return {
      toPath: ''
    }
  },
  created() {
    // 检验小程序环境
    this.delayCheckState()
  },
  methods: {
    // 检验小程序环境
    delayCheckState() {
      console.log('环境状态 = ', WechatState.isWechat)
      if (WechatState.isWechat) {
        const url = window.location.href
        const token = this.getQueryString(url, 'token')
        const uuid = this.getQueryString(url, 'uuid')
        const app_code = this.getQueryString(url, 'app_code')
        console.log('小程序 ', uuid)
        console.log('小程序 ', token)
        const uid = Cookie.get('uid')
        console.log('小程序 ', uid)
        const wxopenid = this.getQueryString(url, 'wxopenid') || ''
        if (token && uuid && !uid) {
          Cookie.set('access_token', token, { expires: 365 })
          Cookie.set('app_code', app_code, { expires: 365 })
          Cookie.set('uuid', uuid, { expires: 365 })
          if (wxopenid) {
            Cookie.set('wxopenid', wxopenid, { expires: 365 })
          }
          this.$router.push({ path: '/mwxLog', query: { token: token, uuid: uuid, app_code: app_code }})
        }
      }
    },
    reload() {
      this.isRouterAlive = false
      this.$nextTick(function() {
        this.isRouterAlive = true
      })
    },
    // 获取地址栏参数
    getQueryString(url, param) {
      // param为要获取的参数名 注:获取不到是为null
      var arr = url.split('?') // 分割域名和参数界限
      if (arr.length > 1) {
        arr = arr[1].split('&') // 分割参数
        for (var i = 0; i < arr.length; i++) {
          var tem = arr[i].split('=') // 分割参数名和参数内容
          if (tem[0] === param) {
            return tem[1]
          }
        }
        return null
      } else {
        return null
      }
    }
  }
}
</script>
<style>
.van-checkbox--disabled {
  color: #323233 !important;
  background: transparent !important;
}
.van-checkbox__label--disabled {
  color: #323233 !important;
}
/* .van-checkbox--disabled .van-icon{
  color: #fff !important;
  border-color: #ee2e2e !important;
  background: #ee2e2e !important;
} */
</style>
<style lang="scss">
html,
body {
  height: 100%;
  width: 100%;
}
body {
  margin: 0;
}
* {
  margin: 0;
  padding: 0;
}
p {
  margin: 0;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  padding: 0;
  // background: #fff;
  margin: 0;
}
input,
button {
  border: none;
  outline: none;
}
li {
  list-style: none;
}
a {
  text-decoration: none;
}
ol,
ul,
dl {
  margin: 0;
  padding: 0;
}
a:focus,
a:active {
  outline: none;
}
ul li {
  list-style: none;
}
div:focus {
  outline: none;
}
a {
  text-decoration: none;
}
a:focus,
a:active {
  outline: none;
}

a,
a:focus,
a:hover {
  cursor: pointer;
  color: inherit;
  text-decoration: none;
}
ol,
ul,
dl {
  margin: 0;
  padding: 0;
}
.clearfix {
  &:after {
    visibility: hidden;
    display: block;
    font-size: 0;
    content: " ";
    clear: both;
    height: 0;
  }
}
</style>
